import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { useCallback } from 'react';
import { useNotificationMessages } from 'utils/notifications';
import { getErrorMessage } from 'utils/errors';
import { ResponseEnvelope } from 'types/responseEnvelope';
import { useDelete } from 'hooks/useDelete';
import { usePost } from 'hooks/usePost';

interface DisableStaffDialogProps {
  active: boolean;
  onToggle: () => void;
  verb?: 'disable' | 're-enable';
  staffName: string;
  staffId?: string;
  agencyId: string;
}

export const DisableStaffDialog = ({ active, onToggle, verb = 'disable', staffName, staffId, agencyId }: DisableStaffDialogProps) => {
  const [isDisabling, disableUser] = useDelete<ResponseEnvelope<any>>(`/partner-api/agencies/${agencyId}/users/${staffId}`);
  const [isReenabling, reenableUser] = usePost<ResponseEnvelope<any>>(`/partner-api/agencies/${agencyId}/users/${staffId}/re-enable`);
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();

  const onConfirm = useCallback(async () => {
    try {
      const action = verb === 'disable' ? disableUser : reenableUser;
      const response = await action();
      showSuccessMessage(response.message);
      onToggle();
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [verb, disableUser, reenableUser, onToggle, showErrorMessage, showSuccessMessage]);

  const isLoading = isDisabling || isReenabling;

  return (
    <Dialog open={active} onClose={onToggle}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            {verb === 'disable' ? 'Disable' : 'Enable'} staff member
          </Typography>
          <IconButton aria-label="close" title="Close" onClick={onToggle} sx={{ color: (theme) => theme.palette.grey[500] }}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        Do you want to {verb} access for {staffName}?
      </DialogContent>
      <DialogActions>
        <Button onClick={onToggle}>Cancel</Button>
        <LoadingButton color={verb === 'disable' ? 'error' : 'primary'} variant="contained" loading={isLoading} onClick={onConfirm}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
